.spacer-left {
  padding-left: calc(50% - 572px) !important;
}

.contact-form {
  border-top: 1px solid rgba(244, 244, 245, 0.2);
  border-bottom: 1px solid rgba(244, 244, 245, 0.2);
}

.contact-label {
  font-size: 14px;
  line-height: 21px;
}

.form-wrapper .form-control:not(textarea) {
  height: 44px;
  padding: 0 16px;
}

.form-wrapper .form-control::placeholder {
  color: #aaa9b1;
}

.form-wrapper .form-control:focus {
  border: 1px solid #ced4da;
  color: var(--blackps);
  background-color: var(--whiteps);
}

.number-field select {
  flex: 0 0 20% !important;
  border-right: 0 !important;
}

.number-field input {
  border-left: 0 !important;
}

.contact-submit {
  height: 60px;
  font-size: 18px;
  line-height: 28px;
}

@media (max-width: 1200px) {
  .spacer-left {
    padding-left: calc(50% - 468px) !important;
    padding-right: calc(50% - 468px) !important;
  }
}

@media (max-width: 992px) {
  .contact-map {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 991px) {
  .spacer-left {
    padding-left: calc(50% - 352px) !important;
    padding-right: calc(50% - 352px) !important;
  }
  .contact-map {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .spacer-left {
    padding-left: calc(50% - 262px) !important;
    padding-right: calc(50% - 262px) !important;
  }
}
@media (max-width: 575px) {
  .spacer-left {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
