/* sponsors page */
.spnsr-card {
  height: 100%;
}
.spnsr-card img {
  width: 100%;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.contact-for-ad .contact-ad-wrapper {
  padding: 30px 89.5px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 767px) {
  /* sponsors page */
  .contact-for-ad .contact-ad-wrapper {
    padding: 30px;
  }
  .contact-ad-wrapper .col-lg-3 img {
    height: 170px;
  }
}
@media (max-width: 575px) {
  /* sponsors page */
  .contact-for-ad .contact-ad-wrapper {
    padding: 20px 15px;
  }
  /* sponsors page ends */
}
