.submit-card {
  height: 100%;
  padding: 24px;
  border-radius: 30px;
  word-break: break-word;
  border: 1px solid rgba(244, 244, 245, 0.3);
}

.submit-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 1.4rem;
  background-color: var(--primary-color);
}
