.world-news-wrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background-color: var(--blueps);
}

.world-news-wrapper .world-text,
.world-news-wrapper .world-image {
  flex: 0 0 50%;
}

.world-news-wrapper .world-text {
  padding: 36px;
}

.world-news-wrapper .world-image {
  height: 500px;
}

.world-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* top match for you styling */
.filter-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 24px;
}

.filter-list .filter-button {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 24px;
  border-radius: 50px;
  border: 1px solid var(--whiteps);
}

.filter-list .filter-button.active,
.filter-list .filter-button:hover {
  color: var(--whiteps);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.topic-cards .topic-image {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.topic-cards .topic-image .tag-lg {
  position: absolute;
  top: 6px;
  left: 4px;
}

.col-lg-8 .topic-cards .topic-image img {
  height: 160px;
}

.topic-cards .topic-image img {
  width: 100%;
  object-fit: cover;
  /* height: 300px; */
  display: block;
  margin: 0 auto;
}

.topic-detail-wrapper span:not(:last-child)::after {
  /* content: "";
  width: 4px;
  height: 4px;
  background-color: var(--black-color-40);
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  border-radius: 50px; */
}

.topic-autor-name{
  margin-right: 15px;
}
/* top match for you styling ends */

/* keep in touch card styling */
.keep-in-touch {
  padding: 40px 20px;
  text-align: center;
  border-radius: 5px;
}

.keep-in-touch form p:nth-child(1) {
  font-weight: 600;
}

/* keep in touch card styling ends */
/* more topics styling */
.more-topic-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  row-gap: 14px;
}

.more-topic-list a {
  display: inline-block;
  padding: 10px 24px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #121212;
  border-radius: 50px;
  background-color: var(--whiteps);
  border: 1px solid rgba(18, 18, 18, 0.15);
}

.more-topic-list a:hover {
  color: var(--whiteps);
  background-color: var(--primary-color);
}

/* more topics styling ends */
@media (max-width: 991px) {
  .world-news-wrapper .world-image {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .world-news-wrapper {
    flex-direction: column-reverse;
  }

  .world-news-wrapper .world-image {
    width: 100%;
  }

  .world-image img {
    height: 260px;
  }
}

@media (max-width: 575px) {
  .world-news-wrapper .world-text {
    padding: 20px 30px;
  }

  /* more topics */
  .more-topic-list {
    row-gap: 10px;
  }

  .more-topic-list a {
    padding: 7px 15px;
  }

  /* more topics ends */
  /* filter buttons */
  .filter-list {
    gap: 10px;
  }

  .filter-list .filter-button {
    padding: 7px 15px;
  }
  .side-advt img {
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    display: flex;
}

  /* filter buttons ends */
}
