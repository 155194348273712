.news-slider .text-slider-wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 350px;
  padding: 40px 30px;
  color: var(--blackps);
  border-radius: 5px;
  background-color: var(--whiteps);
}

.news-next,
.news-prev {
  height: 35px;
  border-radius: 100%;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-next:hover,
.news-prev:hover {
  transition: 300ms ease-in-out;
  background: #ca2132;
  color: white;
}

.text-slider-wrapper .date {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  margin-top: 68px;
}

.text-slider-wrapper a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.text-slider-wrapper a:hover {
  color: var(--primary-color);
}

.news-slider .image-slider-wrapper {
  position: relative;
  text-align: right;
  max-width: 580px;
  border-radius: 5px;
  overflow: hidden;

  margin-left: auto;
}

.image-slide {
  height: 500px;
}

.image-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: var(--whiteps);
}

.slider-buttons div {
  cursor: pointer;
}

.video-aside,
.news-aside {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.news-aside:last-child {
  margin-bottom: 0;
}

.news-aside .aside-image {
  width: 90px;
}

/* video section styling */
.video-aside {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}
.video-aside:last-child {
  margin-bottom: 0;
}
.video-aside .aside-image {
  width: 90px;
  height: 90px;
}
.video-aside .aside-image img {
  height: 100% !important;
}
.video-big {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}
.video-big:hover .video-overlay {
  opacity: 1;
  visibility: visible;
}
.video-big .video-overlay {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 50px 28px 28px;
  background: linear-gradient(
    0deg,
    #000000 43.83%,
    rgba(0, 0, 0, 0.490196) 69.65%,
    rgba(0, 0, 0, 0) 93.51%
  );
  transition: all 0.3s ease;
}
.video-big img {
  width: 100%;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.more-videos {
  height: 520px;
  overflow-y: scroll;
  scrollbar-color: var(--primary-color) rgba(255, 255, 255, 0.1);
  scrollbar-width: thin !important;
}
.more-videos .aside-image {
  position: relative;
}
.more-videos .aside-image .play-button img {
  width: 30px;
  height: 30px;
}
.more-videos::-webkit-scrollbar {
  width: 10px;
}
.more-videos::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: var(--primary-color);
  /* border: 1px solid rgba(255, 255, 255, 0.7); */
}
.more-videos::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
}
/* video section styling ends */

.aside-image img {
  height: 113px;
  width: 90px;
  border-radius: 5px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .spacer-left {
    padding-left: calc(50% - 262px) !important;
    padding-right: calc(50% - 262px) !important;
  }

  /* utils */
  .spacer-y {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mt-30 {
    margin-top: 20px;
  }

  .slide-button {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .section-header {
    gap: 10px;
  }

  .tag {
    font-size: 14px;
  }

  .heading-md {
    font-size: 20px;
    line-height: 30px;
  }

  /* utils ends */
  /* top nav */
  .top-nav-wrapper {
    padding: 8px 0;
  }

  .top-nav p,
  .top-nav {
    font-size: 12px;
  }

  /* top nav ends */
  /* news slider */
  .news-slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .news-slider .image-slider-wrapper {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }

  .image-slide {
    height: 250px;
  }

  .text-slider-wrapper .date {
    margin-top: 40px;
  }

  .news-slider .text-slider-wrapper {
    border-radius: 0 0 5px 5px;
    position: relative;
    top: unset;
    bottom: 0;
    width: 100%;
    transform: unset;
  }

  /* news slider ends */
  /* world news */
  .world-news-wrapper {
    flex-direction: column-reverse;
  }

  .world-news-wrapper .world-image {
    width: 100%;
  }

  .world-image img {
    height: 260px;
  }

  /* world news ends */
  /* keep form */
  .keep-form {
    padding: 40px 70px;
  }

  /* keep form ends */
  /* footer */
  .footer-row .footer-col {
    flex: 0 0 100% !important;
  }

  .footer-col .footer-list,
  .footer-col .footer-cont-text {
    font-size: 14px;
  }

  /* footer ends */
  /* about page */
  /* yt video section */
  .yt-video iframe {
    height: 290px;
  }

  /* about page ends */
}
