.trending-news {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.trend-news-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
}

.trend-news {
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: 10px;
}

.trend-news .news li {
  margin-right: 20px;
}
.tag:hover{
  background-color: #FFF;
}
/* .marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
} */

.marquee-container .marquee a {
  margin-right: 20px !important;
  display: inline-block;
  position: relative;
}
.marquee-container .marquee a::after {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--primary-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -11px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@media (min-width: 992px) {
  .spacer-left {
    padding-left: calc(50% - 472px);
  }

  .menu a.active {
    color: var(--primary-color);
    text-decoration: underline;
  }

  /* trending news */
  .trend-news {
    flex: 0 0 60%;
    overflow: hidden;
  }

  /* contact */
  .contact-map {
    width: 100%;
    height: 100%;
  }

  /* contact ends */
}

@media (max-width: 991px) {
  .spacer-left {
    padding-left: calc(50% - 352px) !important;
    padding-right: calc(50% - 352px) !important;
  }

  /* utils */
  p {
    font-size: 14px;
  }

  .para-1 {
    font-size: 16px;
  }

  /* utils ends */
  /* navigation */
  .hamburger {
    cursor: pointer;
    display: block !important;
  }

  .navigation .item-left .logo {
    height: 50px;
  }

  .item-right .menu {
    position: absolute;
    display: block !important;
    left: 0;
    background: #121212;
    height: 0;
    width: 100%;
    z-index: 9;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .menu.active {
    height: unset;
    overflow: visible;
  }

  .menu a {
    padding: 10px 0;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .menu a.active {
    color: var(--whiteps);
    background-color: var(--primary-color);
  }

  /* navigation ends */
  /* trending news */
  .trend-news-wrapper {
    flex-wrap: wrap;
    overflow: hidden;
    gap: 10px;
    justify-content: center;
  }

  /* trending news ends */
  /* world news */
  .world-news-wrapper .world-image {
    height: 400px;
  }

  /* world news ends */
  /* footer */
  .footer-row .footer-col {
    flex: 0 0 50% !important;
    margin-bottom: 30px;
    padding-right: 20px;
  }

  .footer-row .footer-col:nth-child(1) img {
    width: 200px;
  }

  /* footer ends */
  /* contact */
  .contact-map {
    width: 100%;
    height: 300px;
  }

  /* contact ends */
}

@media (max-width: 767px) {
  .spacer-left {
    padding-left: calc(50% - 262px) !important;
    padding-right: calc(50% - 262px) !important;
  }

  /* utils */
  .spacer-y {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mt-30 {
    margin-top: 20px;
  }

  .slide-button {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .section-header {
    gap: 10px;
  }

  .tag {
    font-size: 14px;
  }
  .heading-md {
    font-size: 20px;
    line-height: 30px;
  }

  /* utils ends */
  /* top nav */
  .top-nav-wrapper {
    padding: 8px 0;
  }

  .top-nav p,
  .top-nav {
    font-size: 12px;
  }

  /* top nav ends */
  /* news slider */
  .news-slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .news-slider .image-slider-wrapper {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }

  .image-slide {
    height: 250px;
  }

  .text-slider-wrapper .date {
    margin-top: 40px;
  }

  .news-slider .text-slider-wrapper {
    border-radius: 0 0 5px 5px;
    position: relative;
    top: unset;
    bottom: 0;
    width: 100%;
    transform: unset;
  }

  /* news slider ends */
  /* world news */
  .world-news-wrapper {
    flex-direction: column-reverse;
  }

  .world-news-wrapper .world-image {
    width: 100%;
  }

  .world-image img {
    height: 260px;
  }

  /* world news ends */
  /* keep form */
  .keep-form {
    padding: 40px 70px;
  }

  /* keep form ends */
  /* footer */
  .footer-row .footer-col {
    flex: 0 0 100% !important;
  }

  .footer-col .footer-list,
  .footer-col .footer-cont-text {
    font-size: 14px;
  }

  /* footer ends */
  /* about page */
  /* yt video section */
  .yt-video iframe {
    height: 290px;
  }

  /* about page ends */
}

@media (max-width: 575px) {
  .spacer-left {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .section-header hr {
    display: none;
  }

  .section-header h3 {
    flex: auto;
  }

  /* top nav */
  .top-nav {
    flex-direction: column;
    justify-content: center;
  }

  .top-nav p,
  .top-nav {
    font-size: 10px;
    line-height: 16px;
  }

  /* top nav ends */
  /* navigation */
  .navigation .item-left .logo {
    height: 40px;
  }

  .navigation .item-right {
    gap: 15px;
  }

  /* navigation ends */
  /* trending news */
  .trend-news {
    flex-direction: column;
    text-align: center;
  }

  /* trending news ends */
  /* news slider */
  .news-slider .text-slider-wrapper {
    padding: 20px 30px;
  }

  /* news slider ends */
  /* topic cards */
  .col-lg-8 .topic-cards .topic-image img {
    height: 100%;
  }

  /* topic cards ends */
  /* video section */
  .video-big .video-overlay {
    padding: 30px 15px 15px;
    background: linear-gradient(0deg, #000000 5%, rgba(0, 0, 0, 0) 100%);
  }

  /* video section ends */
  /* world news */
  .world-news-wrapper .world-text {
    padding: 20px 30px;
  }

  /* world news ends */
  /* filter buttons */
  .filter-list {
    gap: 10px;
  }

  .filter-list .filter-button {
    padding: 7px 15px;
  }

  /* filter buttons ends */
  /* more topics */
  .more-topic-list {
    row-gap: 10px;
  }

  .more-topic-list a {
    padding: 7px 15px;
  }

  /* more topics ends */
  /* keep form */
  .keep-form {
    padding: 20px 30px;
  }

  /* keep form ends */
  /* footer */
  .footer-row .footer-col {
    padding-right: 0;
  }

  /* footer ends */
  /* copyright */
  .copyright p {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
    color: var(--black-color-40);
  }

  /* copyright ends */

  /* intel page */
  .para-1 {
    font-size: 14px;
  }

  /* recent news */
  .col-lg-6:nth-child(2) .recent-news-post {
    flex-direction: column;
  }

  .col-lg-6:nth-child(2) .recent-news-image {
    flex: 0 0 100%;
  }

  /* newsletter */
  .newsletter-form {
    flex-direction: column;
  }

  /* pagination */
  .pagination-wrapper {
    gap: 10px;
  }

  .pagination-wrapper div:nth-child(1) {
    order: 2;
  }

  .pagination-wrapper div:nth-child(2) {
    order: 1;
    flex: 0 0 100%;
  }

  .pagination-wrapper div:nth-child(3) {
    order: 3;
  }

  /* intel page ends */
}
