.keep-in-touch-long {
  background-image: url(../../../images/keep-in-touch-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.keep-form {
  padding: 70px;
}

.keep-form.white-bg {
  background-color: rgba(255, 255, 255, 0.95);
}

.keep-form .theme-form::placeholder {
  color: rgb(127, 125, 137);
}

.keep-form .theme-form:focus {
  color: var(--blackps);
}

@media (max-width: 767px) {
  /* world news */
  .world-news-wrapper {
    flex-direction: column-reverse;
  }

  .world-news-wrapper .world-image {
    width: 100%;
  }

  .world-image img {
    height: 260px;
  }

  /* world news ends */
  /* keep form */
  .keep-form {
    padding: 40px 70px;
  }

  /* keep form ends */
}

@media (max-width: 575px) {
  /* keep form */
  .keep-form {
    padding: 20px 30px;
  }

  /* keep form ends */
}
