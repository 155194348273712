.top-nav-wrapper {
  padding: 15px 0;
  background-color: var(--secondary-color);
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.top-nav .top-right,
.top-nav .top-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.top-nav .top-right .day {
  padding-right: 10px;
  border-right: 1px solid white;
}

.top-socials {
  display: flex;
  gap: 20px;
}

.header {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: var(--blackps);
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navigation .item-left {
  flex: 0 0 30%;
}

.navigation .item-left .logo {
  height: 70px;
}

.navigation .item-right {
  flex: 0 0 auto;
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;
}

.item-right .menu {
  display: flex;
  gap: 30px;
}

.hamburger {
  display: none;
  right: 0;
  position: relative;
  transition: all 0.3s ease;
}

.hamburger span {
  display: block;
  width: 30px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  right: 0;
  background: var(--primary-color);
  z-index: 1;
  transform-origin: center;
  transition: all 0.3s ease;
}

.hamburger.active span {
  position: absolute;
  margin: 0;
}

.hamburger.active span:nth-child(1) {
  transform: rotate(45deg);
}

.hamburger.active span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger.active span:nth-child(2) {
  width: 0;
}

.hamburger span:last-child {
  margin: 0;
}
/* search in navbar */

.search-toggler {
  cursor: pointer;
}

.item-right .search-form.active {
  padding: 20px;
  height: 80px;
  overflow: visible;
  opacity: 1;
  visibility: visible;
}
.item-right .search-form {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  padding: 0;
  background-color: var(--blackps);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  border-bottom: 1px solid var(--primary-color);
  transition: all 0.3s ease;

  height: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.search-input {
  position: relative;
}
.search-input .search-area {
  padding-right: 40px;
  padding-left: 10px;
  border-radius: 0 !important;
  color: var(--blackps);
  background-color: var(--whiteps);
}
.search-input .search-area::placeholder {
  color: var(--secondary-color);
}
.search-input .search-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  /* border-radius: 50%; */
  font-size: 14px;
  border: none;
  color: var(--whiteps);
  background-color: var(--primary-color);
}

.menu-list {
  text-decoration: none !important;
}
.noData {
  color: white !important;
  text-decoration: none !important;
}
