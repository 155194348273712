/* resources page */
.vfw-text {
  position: relative;
  max-height: 150px;
  overflow: hidden;
  /* transition: all 1s ease; */
}
.vfw-text.expanded {
  max-height: 100%;
}

.read-less,
.read-more {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 30px 0;
  background-image: linear-gradient(to bottom, transparent, black);
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
.vfw-text.expanded .read-more {
  opacity: 0;
  visibility: hidden;
}
.vfw-text.expanded .read-less {
  opacity: 1;
  visibility: visible;
}
.read-less {
  position: relative;
  padding-bottom: 0;
  background-image: none;
  opacity: 0;
  visibility: hidden;
}
