:root {
  --primary-color: #ca2132;
  --primary-color-light: #ffcbd0;
  --primary-color-dark: #990010;

  --secondary-color: #444f23;
  --secondary-color-light: #d3e2a6;
  --secondary-color-dark: #2b350b;

  --black-color-100: #1f1d27;
  --black-color-60: #7f7d89;
  --black-color-40: #aaa9b1;
  --black-color-20: #d4d4d8;
  --black-color-10: #eae9eb;
  --black-color-05: #f4f4f5;

  --whiteps: #ffffff;
  --blackps: #000000;
  --blueps: #007bff;
}

*,
*::before,
*::after,
html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--whiteps);
  background-color: var(--blackps);
  font-family: "Poppins", sans-serif;
}

/* utils */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 36px;
  line-height: 54px;
}

h2 {
  font-size: 30px;
  line-height: 45px;
}

h3 {
  font-size: 24px;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  line-height: 21px;
}

p {
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

a {
  color: var(--whiteps);
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--primary-color);
}

ul {
  margin: 0;
}

hr {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255);
  opacity: 0.6;
}

.heading-md {
  font-size: 34px;
  line-height: 60px;
}

.para-1 {
  font-size: 18px;
  line-height: 32px;
}

.para-2 {
  font-size: 16px;
  line-height: 30px;
}

.para-3 {
  font-size: 14px;
  line-height: 28px;
}

.para-4 {
  font-size: 12px;
  line-height: 22px;
}

.fw-600 {
  font-weight: 600;
}

.primary-text {
  color: var(--primary-color);
}

.muted-text {
  color: var(--black-color-40);
}

.light-text {
  color: var(--black-color-05);
}

.primary-bg {
  background-color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.blue-bg {
  background-color: var(--blueps);
}

.white-bg {
  color: var(--blackps);
  background-color: var(--whiteps);
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.small-width {
  max-width: 550px;
}

.tag-lg,
.tag-md,
.tag-sm,
.tag {
  min-width: 146px;
  display: inline-block;
  text-align: center;
  padding: 6px 14px;
  border-radius: 50px;
  color: var(--whiteps);
  background-color: var(--primary-color);
}

.tag-sm {
  min-width: 100px;
  font-size: 11px;
  line-height: 16.5px;
  padding: 3px 10px;
}

.tag-md {
  min-width: 92px;
  font-size: 13px;
  padding: 4px 16px;
  line-height: 19.5px;
}

.tag-lg {
  min-width: unset;
}

/* .container {
  max-width: 1170px;
  margin: auto;
} */
.spacer-y {
  padding-top: 50px;
  padding-bottom: 50px;
}

.white-bg a {
  color: var(--blackps);
}

.slider-nav-buttons {
  display: flex;
  gap: 10px;
}

.slide-button {
  height: 40px;
  width: 40px;
  font-size: 1.2rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--secondary-color-dark);
  background-color: var(--whiteps);
  transition: all 0.3s ease;
}

.slide-button:hover {
  color: var(--whiteps);
  background-color: var(--primary-color);
}

.site-link {
  font-weight: 600;
}

.secondary-button,
.theme-button {
  border: none;
  display: inline-block;
  padding: 10px 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50px;
  text-align: center;
  color: var(--whiteps);
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}

.theme-button:hover {
  color: var(--whiteps);
  background-color: var(--primary-color-dark);
}

.secondary-button {
  color: var(--black-color-100);
  background-color: var(--whiteps);
}

.secondary-button:hover {
  color: var(--primary-color);
}

.form-select:focus,
.form-control:focus {
  color: var(--whiteps);
  box-shadow: none;
  background-color: transparent;
}

.white-bg .form-control:focus {
  border: 1px solid var(--primary-color);
}

.theme-form {
  height: 40px;
  padding: 10px 24px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50px !important;
  border-radius: 1px solid var(--whiteps);
  /* color: var(--whiteps); */
  background-color: transparent;
}

.theme-form::placeholder {
  color: var(--black-color-05);
  opacity: 0.4;
}

/* body {
  overflow-anchor: none;
}
  */
/* utils ends */
.number-list li:not(.previous, .next) a {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--whiteps);
  background-color: transparent;
  transition: all 0.3s ease;
}

.number-list li:not(.previous, .next) a:hover,
.number-list li.active a {
  color: var(--blackps) !important;
  background-color: #f9f5ff;
}

.cover-img {
  aspect-ratio: 16/9 !important;
  object-fit: cover !important;
}

.sponser-content-div{
  justify-content: center;
}