.section-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.section-header h3 {
  flex: 0 0 auto;
}

.top-stories .slider-nav-buttons {
  justify-content: flex-end;
}

.story-cards {
  border-radius: 5px;
  height: 100%;
  background-color: var(--whiteps);
}

.story-text {
  padding-right: 30px;
}

.story-cards .story-card-image {
  position: relative;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 269px;
  overflow: hidden;
}

.story-cards .story-card-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.story-cards:hover img {
  transform: scale(1.1);
}

.story-card-image .tag-lg {
  position: absolute;
  top: 4px !important;
  left: 4px;
  z-index: 2;
}

.story-cards .story-card-text {
  color: var(--blackps);
  padding: 17px 15px;
  border-radius: 0 0 5px 5px;
}

.story-card-text h5 {
  font-weight: 600;
}
.story-news-image {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.top-stories-image {
  max-height: 400px;
  width: 100%;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
