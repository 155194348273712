.col-lg-6:nth-child(1) .recent-news-image {
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}

.col-lg-6:nth-child(2) .recent-news-image {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  flex: 0 0 55%;
}

.col-lg-6:nth-child(2) .recent-news-post {
  display: flex;
  align-items: center;
  gap: 20px;
}

.recent-news-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.news-content-wrapper a {
  display: flex;
}

.news-arrow-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
