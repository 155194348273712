/* about page */
.about-socials {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.about-socials a {
  display: inline-flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--black-color-60);
  background-color: var(--whiteps);
  border-radius: 8px;
}

/* subscribe section */
.subscribe {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.subscribe-cards {
  position: relative;
  padding: 56px 0 32px 0;
  color: var(--blackps);
  border-radius: 16px;
  margin-top: 28px;
  background-color: var(--whiteps);
}

.subscribe-cards a:hover {
  color: var(--whiteps);
}

.subscribe-cards .subs-icon {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: var(--whiteps);
  background-color: var(--primary-color);
  border-radius: 50%;
}

.subscribe-cards .subs-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

/* yt video section */
.yt-video iframe {
  height: 500px;
  width: 100%;
  border-radius: 10px;
}

/* about page ends */

.text-left{
  text-align: left !important;
}