:root {
  --primary-color: #ca2132;
  --primary-color-light: #ffcbd0;
  --primary-color-dark: #990010;

  --secondary-color: #444f23;
  --secondary-color-light: #d3e2a6;
  --secondary-color-dark: #2b350b;

  --black-color-100: #1f1d27;
  --black-color-60: #7f7d89;
  --black-color-40: #aaa9b1;
  --black-color-20: #d4d4d8;
  --black-color-10: #eae9eb;
  --black-color-05: #f4f4f5;

  --whiteps: #ffffff;
  --blackps: #000000;
  --blueps: #007bff;
}

*,
*::before,
*::after,
html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--whiteps);
  background-color: var(--blackps);
  font-family: "Poppins", sans-serif;
}

/* utils */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 36px;
  line-height: 54px;
}

h2 {
  font-size: 30px;
  line-height: 45px;
}

h3 {
  font-size: 24px;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  line-height: 21px;
}

p {
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

a {
  color: var(--whiteps);
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--primary-color);
}

ul {
  margin: 0;
}

hr {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255);
  opacity: 0.6;
}

.heading-md {
  word-wrap: break-word;

  font-size: 34px;
  line-height: 60px;
}

.para-1 {
  font-size: 18px;
  line-height: 32px;
}

.para-2 {
  font-size: 16px;
  line-height: 30px;
}

.para-3 {
  font-size: 14px;
  line-height: 28px;
}

.para-4 {
  font-size: 12px;
  line-height: 22px;
}

.fw-600 {
  font-weight: 600;
}

.primary-text {
  color: var(--primary-color);
}

.muted-text {
  color: var(--black-color-40);
}

.light-text {
  color: var(--black-color-05);
}

.primary-bg {
  background-color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.blue-bg {
  background-color: var(--blueps);
}

.white-bg {
  color: var(--blackps);
  background-color: var(--whiteps);
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.small-width {
  max-width: 550px;
}

.tag-lg,
.tag-md,
.tag-sm,
.tag {
  min-width: 146px;
  display: inline-block;
  text-align: center;
  padding: 6px 14px;
  border-radius: 50px;
  color: var(--whiteps);
  background-color: var(--primary-color);
}

.tag-sm {
  min-width: 100px;
  font-size: 11px;
  line-height: 16.5px;
  padding: 3px 10px;
}

.tag-md {
  min-width: 92px;
  font-size: 13px;
  padding: 4px 16px;
  line-height: 19.5px;
}

.tag-lg {
  min-width: unset;
}

/* .container {
  max-width: 1170px;
  margin: auto;
} */
.spacer-y {
  padding-top: 50px;
  padding-bottom: 50px;
}

.white-bg a {
  color: var(--blackps);
}

.slider-nav-buttons {
  display: flex;
  gap: 10px;
}

.slide-button {
  height: 40px;
  width: 40px;
  font-size: 1.2rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--secondary-color-dark);
  background-color: var(--whiteps);
  transition: all 0.3s ease;
}

.slide-button:hover {
  color: var(--whiteps);
  background-color: var(--primary-color);
}

.site-link {
  font-weight: 600;
}

.secondary-button,
.theme-button {
  border: none;
  display: inline-block;
  padding: 10px 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50px;
  text-align: center;
  color: var(--whiteps);
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}

.theme-button:hover {
  color: var(--whiteps);
  background-color: var(--primary-color-dark);
}

.secondary-button {
  color: var(--black-color-100);
  background-color: var(--whiteps);
}

.secondary-button:hover {
  color: var(--primary-color);
}

.form-select:focus,
.form-control:focus {
  color: var(--whiteps);
  box-shadow: none;
  background-color: transparent;
}

.white-bg .form-control:focus {
  border: 1px solid var(--primary-color);
}

.theme-form {
  height: 40px;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 50px !important;
  border-radius: 1px solid var(--whiteps);
  /* color: var(--whiteps); */
  background-color: transparent;
}

.theme-form::placeholder {
  color: var(--black-color-05);
  opacity: 0.4;
}

/* utils ends */

/* ADS DESIGN */

.web-ads-design {
  background-color: transparent;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 200px;
}

.ads-lt {
  left: calc(50% - 585px - 80px);
  left: -moz-calc(50% - 585px - 80px);
  left: -webkit-calc(50% - 585px - 80px);
}

.ads-rt {
  right: calc(50% - 585px - 80px);
  right: -moz-calc(50% - 585px - 80px);
  right: -webkit-calc(50% - 585px - 80px);
}

@media (min-width: 1350px) {
  .web-ads-design {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  .spacer-left {
    padding-left: calc(50% - 468px) !important;
    padding-right: calc(50% - 468px) !important;
  }

  .world-news-wrapper .world-image {
    height: 400px;
  }

  .navigation .item-left .logo {
    height: 60px;
  }
}

@media (min-width: 992px) {
  .spacer-left {
    padding-left: calc(50% - 472px);
  }

  .menu a.active {
    color: var(--primary-color);
    text-decoration: underline;
  }

  /* trending news */
  .trend-news {
    flex: 0 0 60%;
  }

  /* contact */
  .contact-map {
    width: 100%;
    height: 100%;
  }

  /* contact ends */
}

@media (max-width: 991px) {
  .spacer-left {
    padding-left: calc(50% - 352px) !important;
    padding-right: calc(50% - 352px) !important;
  }

  /* utils */
  p {
    font-size: 14px;
  }

  .para-1 {
    font-size: 16px;
  }

  /* utils ends */
  /* navigation */
  .hamburger {
    cursor: pointer;
    display: block;
  }

  .navigation .item-left .logo {
    height: 50px;
  }

  .item-right .menu {
    position: absolute;
    top: 81px;
    display: block;
    left: 0;
    background: #121212;
    height: 0;
    width: 100%;
    z-index: 9;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .menu.active {
    height: unset;
    overflow: visible;
  }

  .menu a {
    padding: 10px 0;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .menu a.active {
    color: var(--whiteps);
    background-color: var(--primary-color);
  }

  /* navigation ends */
  /* trending news */
  .trend-news-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  /* trending news ends */
  /* world news */

  /* world news ends */
  /* footer */
  .footer-row .footer-col {
    flex: 0 0 50% !important;
    margin-bottom: 30px;
    padding-right: 20px;
  }

  .footer-row .footer-col:nth-child(1) img {
    width: 200px;
  }

  /* footer ends */
  /* contact */
  .contact-map {
    width: 100%;
    height: 300px;
  }

  /* contact ends */
}

@media (max-width: 767px) {
  .spacer-left {
    padding-left: calc(50% - 262px) !important;
    padding-right: calc(50% - 262px) !important;
  }

  /* utils */
  .spacer-y {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mt-30 {
    margin-top: 20px;
  }

  .slide-button {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }

  .section-header {
    gap: 10px;
  }

  .tag {
    font-size: 14px;
  }

  .heading-md {
    font-size: 20px;
    line-height: 30px;
  }

  /* utils ends */
  /* top nav */
  .top-nav-wrapper {
    padding: 8px 0;
  }

  .top-nav p,
  .top-nav {
    font-size: 12px;
  }

  /* top nav ends */
  /* news slider */
  .news-slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .news-slider .image-slider-wrapper {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }

  .image-slide {
    height: 250px;
  }

  .text-slider-wrapper .date {
    margin-top: 40px;
  }

  .news-slider .text-slider-wrapper {
    border-radius: 0 0 5px 5px;
    position: relative;
    top: unset;
    bottom: 0;
    width: 100%;
    transform: unset;
  }

  /* news slider ends */
  /* world news */
  .world-news-wrapper {
    flex-direction: column-reverse;
  }

  .world-news-wrapper .world-image {
    width: 100%;
  }

  .world-image img {
    height: 260px;
  }

  /* world news ends */
  /* keep form */
  .keep-form {
    padding: 40px 70px;
  }

  /* keep form ends */
  /* footer */
  .footer-row .footer-col {
    flex: 0 0 100% !important;
  }

  .footer-col .footer-list,
  .footer-col .footer-cont-text {
    font-size: 14px;
  }

  /* footer ends */
  /* about page */
  /* yt video section */
  .yt-video iframe {
    height: 290px;
  }

  /* about page ends */
}

@media (max-width: 575px) {
  .spacer-left {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .section-header hr {
    display: none;
  }

  .section-header h3 {
    flex: auto;
  }

  /* top nav */
  .top-nav {
    flex-direction: column;
    justify-content: center;
  }

  .top-nav p,
  .top-nav {
    font-size: 10px;
    line-height: 16px;
  }

  /* top nav ends */
  /* navigation */
  .navigation .item-left .logo {
    height: 40px;
  }

  .navigation .item-right {
    gap: 15px;
  }

  /* navigation ends */
  /* trending news */
  .trend-news {
    flex-direction: column;
    text-align: center;
  }

  /* trending news ends */
  /* news slider */
  .news-slider .text-slider-wrapper {
    padding: 20px 30px;
  }

  /* news slider ends */
  /* topic cards */
  .col-lg-8 .topic-cards .topic-image img {
    height: 100%;
  }

  /* topic cards ends */
  /* video section */
  .video-big .video-overlay {
    padding: 30px 15px 15px;
    background: linear-gradient(0deg, #000000 5%, rgba(0, 0, 0, 0) 100%);
  }

  /* video section ends */
  /* world news */
  .world-news-wrapper .world-text {
    padding: 20px 30px;
  }

  /* world news ends */
  /* filter buttons */
  .filter-list {
    gap: 10px;
  }

  .filter-list .filter-button {
    padding: 7px 15px;
  }

  /* filter buttons ends */
  /* more topics */
  .more-topic-list {
    row-gap: 10px;
  }

  .more-topic-list a {
    padding: 7px 15px;
  }

  /* more topics ends */
  /* keep form */
  .keep-form {
    padding: 20px 30px;
  }

  /* keep form ends */
  /* footer */
  .footer-row .footer-col {
    padding-right: 0;
  }

  /* footer ends */
  /* copyright */
  .copyright p {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
    color: var(--black-color-40);
  }

  /* copyright ends */

  /* intel page */
  .para-1 {
    font-size: 14px;
  }

  /* recent news */
  .col-lg-6:nth-child(2) .recent-news-post {
    flex-direction: column;
  }

  .col-lg-6:nth-child(2) .recent-news-image {
    flex: 0 0 100%;
  }

  /* newsletter */
  .newsletter-form {
    flex-direction: column;
  }

  /* pagination */
  .pagination-wrapper {
    gap: 10px;
  }

  .pagination-wrapper div:nth-child(1) {
    order: 2;
  }

  .pagination-wrapper div:nth-child(2) {
    order: 1;
    flex: 0 0 100%;
  }

  .pagination-wrapper div:nth-child(3) {
    order: 3;
  }

  /* intel page ends */
}

/* .marquee-container{
  width: 400px !important;
} */

.marquee-container .overlay {
  visibility: hidden;
}
.marquee-container .marquee {
  --duation: "130s" !important;
}

.content-sec h3 {
  color: var(--primary-color);
  margin-bottom: 15px;
}
.content-sec p {
  margin-bottom: 1.5rem !important;
}
.content-sec ol,
.content-sec ul {
  padding-left: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.content-sec li {
  margin-bottom: 10px;
}
.c-pointer {
  cursor: pointer;
}

body {
  overflow-anchor: none;
}

.react-tel-input input {
  width: 100% !important;
  padding-left: 50px !important;
}
.flag-dropdown .country-list {
  color: var(--blackps);
}
.react-tel-input .country-list .search {
  width: 95%;
}
.react-tel-input .country-list {
  scroll-behavior: auto !important;
}
p img{
  width: 100% !important;
}

/* iframe responsive */

:is(.intel-text) :is(iframe){
  max-width: 100%;
}

.div-404{
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
h1.notfound-404 {
  font-size: 12rem;
  margin-top: 6rem;
  font-weight: 600;
  letter-spacing: 25px;
}

.txt-cap{
  text-transform: capitalize;
}

.sponors-image img{
  max-height: 150px;
    object-fit: cover;
}

.video-lists-li{
  cursor: pointer;
}

.about-thumb .aside-image{
  border-radius: 5px;
  overflow: hidden;
}
.about-thumb .aside-image img{
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
}

.play-button{
  z-index: 9;
}
/* .mx-2{
  margin-left: 2px;
  margin-right: 2px;
} */


.ml-1{
  margin-left: 5px;
}

.mr-1{
  margin-right: 5px;
}

.intel-text img {
  max-width: 100%;
}