.more-news-image video {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
}

.pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(244, 244, 245, 0.3);
}

.number-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.other_tags h1 {
  font-size: inherit;
}
