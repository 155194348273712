.credit-wrapper .credit-author,
.intel-text p {
    color: var(--black-color-40);
}
.intel-text img {
    /* width: 100%;
    height: 450px; */
}

.intel-text p:not(:last-child) {
    margin-bottom: 1rem;
}

.intel-text a {
    color: var(--primary-color);
    /* font-size: 20px; */
    text-decoration: underline;
}

.intel-text a:hover {
    color: var(--primary-color);
}

.credit-wrapper {
    border-left: 2px solid var(--primary-color);
    padding-left: 20px;
}

.credit-wrapper .credit-text {
    font-style: italic;
}

.badge-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.bagde {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.bagde a {
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 10px;
    background-color: var(--primary-color);
}

.bagde a:hover {
    color: var(--whiteps);
    background-color: var(--primary-color-dark);
}

.wrapper-copy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.wrapper-copy .copy-link {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 16px;
    color: #344054;
    font-weight: 600;
    border-radius: 8px;
    background-color: var(--whiteps);
}

.wrapper-copy .copy-link i {
    rotate: 90deg;
}

@media (max-width:767px) {
    .credit-wrapper .credit-text {
        line-height: 28px;
        font-size: 18px;
    }
}

.video-desc{
    margin: 0 auto;
    display: block;
}