.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-row .footer-col {
  flex: 0 0 25%;
}

.footer-row .footer-col:nth-child(1) {
  flex: 0 0 29%;
}

.footer-row .footer-col:nth-child(2) {
  flex: 0 0 15%;
}

.footer-row .footer-col:nth-child(3) {
  flex: 0 0 30%;
}

.footer-row .footer-col:nth-child(4) {
  flex: 0 0 20%;
}

.footer-list li {
  margin-bottom: 10px;
}

.footer-contact .footer-blk,
.footer-contact a {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 27px;
}

.footer-contact:last-child a {
  margin-bottom: 0;
}

.footer-socials {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.copyright {
  padding: 14px 0;
}
.footer-cont-image {
  color: var(--whiteps);
  font-size: 18px;
}
